<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryParams" :model="queryParams" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryParams.qstr" placeholder="日期、主題、備註"></el-input>
        </el-form-item>
        <el-form-item label="分類">
          <el-cascader :props="props" v-model="queryParams.categories" :options="categories" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="標籤">
            <el-select v-model="queryParams.tags" clearable multiple filterable placeholder="請選擇">
              <el-option
                v-for="tag in tags"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id">
              </el-option>
            </el-select>
        </el-form-item>
         <el-form-item>
           <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort,order, queryParams)">查詢</el-button>
            <el-button type="primary" @click="download(sort,order, queryParams)">匯出</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addAudio" v-if="$store.state.auth.role === 'admin'">新增</el-button>
          <el-button type="primary" @click="batchEditAudio" v-if="$store.state.auth.role === 'admin'">批次編輯</el-button>
          <el-button type="danger" @click="batchDelete" v-if="$store.state.auth.role === 'admin'">批次刪除</el-button>
          <el-button type="primary" @click="batchAssign" v-if="$store.state.auth.role === 'admin'">批次個人授權</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
            :data="audios"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
            @sort-change = "sortChange"
            @selection-change="handleSelectionChange"
            :cell-class-name="tableCellClassName"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
            <el-table-column
            fixed
            type="selection"
            width="45">
          </el-table-column>
            <el-table-column
                label="開示日期"
                prop="audio_no"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="開示主題"
                prop="title"
                sortable="custom"
            >
            </el-table-column>
                  <el-table-column
                label="標籤"
            >
             <template slot-scope="scope">
                 <span class="tag_group">
                   <template v-for="audioToAudioTag in scope.row.audioToAudioTags">
                      <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="'失效日期:'+numberToDateString(audioToAudioTag.expiration_date)"
                        v-if="audioToAudioTag.expiration_date !== '4102358400000'"
                        :key="audioToAudioTag.id"
                        ><el-tag :type="audioToAudioTag.tag.type" slot="reference"><i class="el-icon-time"></i>{{audioToAudioTag.tag.name}}</el-tag>
                        </el-popover><el-tag v-else :key="audioToAudioTag.id" :type="audioToAudioTag.tag.type" slot="reference">{{audioToAudioTag.tag.name}}</el-tag></template>
                 </span>
                 
             </template>
            </el-table-column>
            <el-table-column
                label="分類"
                 prop="category"
                 :formatter="categoryFormatter"
                  sortable="custom"
            >
            <!--
            <template slot-scope="scope">
              <keep-alive>
                {{ categoryFormatter(scope.row.category.id)}}
              </keep-alive>
            </template>
            -->
            </el-table-column>
            <el-table-column
                label="生效日期"
                prop="start_date"
                :formatter="dateFormatter"
                width="120px"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="end_date"
                :formatter="dateFormatter"
                width="120px"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
            align="right"
              label="檔案大小"
              prop="audio_file_size"
              :formatter="fileSizeFormatter"
              width="120px"
              sortable="custom"
            >
            </el-table-column>
                <el-table-column
                label="備註"
                prop="description"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="150" v-if="$store.state.auth.role === 'admin'">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editAudio(scope.row.id)"
                  
                  size="mini">
                  編輯
                </el-button>
                <el-button
                  @click.native.prevent="deleteAudio(scope.row.id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
     <el-dialog title="批次修改" :visible.sync="dialogBatchEditFormVisible" width="1100px" v-loading="loading" element-loading-text="作業中">
      <el-form :model="batchForm" ref="batchForm">
        <el-form-item label="標籤動作" :label-width="formLabelWidth" prop="tagAction" >
          <el-radio-group v-model="batchForm.tagAction">
            <el-radio label="append">新增</el-radio>
            <el-radio label="remove">移除</el-radio>
            <el-radio label="replace">取代</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
        <div style="all:initial;">
            <el-transfer
              v-model="batchForm.tags"
              filterable
              :titles="['候選', '已選取']"
              :props="{
                key: 'id',
                label: 'name'
              }"
              :data="tagExps">
              <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag><el-date-picker type="date" value-format="timestamp" placeholder="到期日"  v-model="option.exp"></el-date-picker></span>
            </el-transfer>
        </div>
        </el-form-item>
        <el-form-item label="分類" :label-width="formLabelWidth" prop="category">
          <el-cascader :props="props" v-model="batchForm.category" :options="categories" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="batchForm.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="end_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="batchForm.end_date"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBatchEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBatch">確 定</el-button>
      </div>
     </el-dialog>
      <el-dialog title="新增/編輯音檔" :visible.sync="dialogFormVisible" width="1100px">
      <el-form :model="form" ref="form" :rules="rules" v-loading="loading"
       element-loading-text="上傳中">
        <el-form-item label="開示日期" :label-width="formLabelWidth" prop="audio_no">
          <el-input v-model="form.audio_no" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="開示主題" :label-width="formLabelWidth" prop="title">
           <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
          <div style="all:initial;">
              <el-transfer
                v-model="form.tags"
                filterable
                :titles="['候選', '已加入']"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="tagExps">
                <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag><el-date-picker type="date" value-format="timestamp" placeholder="到期日"  v-model="option.exp"></el-date-picker></span>
              </el-transfer>
          </div>
        </el-form-item>
        <el-form-item label="分類" :label-width="formLabelWidth" prop="category">
          <el-cascader :props="props" v-model="form.category" :options="categories"></el-cascader>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="end_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.end_date"></el-date-picker>
        </el-form-item>
          <el-form-item label="備註" :label-width="formLabelWidth" prop="description">
            <el-input type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="已上傳檔案" :label-width="formLabelWidth">{{ form.audio_file_info }}</el-form-item>
        <el-form-item label="上傳檔案" :label-width="formLabelWidth" prop="fileList">
          <!--
            action="https://api.treasure.gebis.net/audio/upload"
            action="http://127.0.0.1:3000/audio/upload"
           -->
          <el-upload
            ref='upload'
            action="https://api.treasure.gebis.net/audio/upload"
            accept="audio/mp3,audio/x-ms-wma,video/x-ms-wma,audio/x-m4a,audio/flac"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-change="handleChange"
            :on-error="handleError"
            :multiple="upload.multiple"
            :headers="upload.headers"
            :limit="upload.limit"
            :auto-upload="upload.autoUpload"
            :data="upload.data"
            >
            <el-button size="small" type="primary">點擊上傳</el-button>
            <div slot="tip" class="el-upload__tip">只能上傳mp3,wma,m4a,flac檔案</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  <el-dialog title="個人授權" :visible.sync="dialogAssignVisible" width="1100px" v-loading="loading" element-loading-text="作業中">
      <el-form :model="assignForm" ref="assignForm" :rules="assignRules" >
        <el-form-item label="授權動作" :label-width="formLabelWidth" prop="action" >
          <el-radio-group v-model="assignForm.action">
            <el-radio label="append">新增(若存在則更新失效日期)</el-radio>
            <el-radio label="remove">移除</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="人員" :label-width="formLabelWidth" prop="accounts">
          <el-input type="textarea" autosize v-model="queryString"></el-input>
          <el-button @click="remoteOptionQuery(queryString)">查詢</el-button>
          <el-button @click="assignForm.accounts=[]">清除全部</el-button>
          <el-table
      :data="assignForm.accounts"
      style="width: 100%">
      <el-table-column
              type="index"
              :index="indexFormatter2"
              fixed
              >
            </el-table-column>
      <el-table-column
      sortable
        prop="name"
        label="名稱"
        width="180">
      </el-table-column>
      <el-table-column
      sortable
        prop="organization.name"
        label="單位"
        width="180">
      </el-table-column>
      <el-table-column
      label="操作"
      width="120">
      <template slot-scope="scope">
        <el-button
          @click="assignForm.accounts.splice(scope.$index, 1)"
          type="text"
          size="small">
          移除
        </el-button>
      </template>
    </el-table-column>
    </el-table>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="expiration_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="assignForm.expiration_date"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAssignVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAssign">確 定</el-button>
      </div>
     </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import API from '@/api/'
import moment from "moment-timezone";
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'AudioList',
  created () {
      this.$store.dispatch('audio/list', {max: this.pageSize, offset: 0, sort: this.sort, order: this.order}, {
          root: true
      });
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('category/list', {max: 1000, offset: 0}, {
          root: true
      });
  },
  data() {
        return {
          pageSize:50,
          currentPage:0,
          loading: false,
          multipleSelection: [],
          queryString: '',
          assignForm: {
            action: '',
            accounts: [],
            expiration_date: null,
          },
          batchForm: {
            tagAction: '',
            tags: [],
            category: [],
            start_date: null,
            end_date: null
          },
          queryParams: {
            tags:[],
            categories:'',
            queryParams:''
          },
          form: {
            id: null,
            audio_no:null,
            title:null,
            tags:[],
            category:'',
            start_date:null,
            end_date:null,
            description:'',
            fileList: [],
            audio_file_info:''
          },
          upload:{
            headers:{Authorization:""},
            multiple: false,
            limit: 1,
            autoUpload: false,
            data:{audio_id:0}
          },
          props: {
            value: 'id',
            label: 'name',
            checkStrictly: true
          },
          dialogBatchEditFormVisible: false,
          dialogFormVisible: false,
          dialogAssignVisible: false,
          formLabelWidth: '120px',
          rules: {
            audio_no: [
              { required: true, message: '請輸開示日期', trigger: 'blur' },
            ],
            title: [
              { required: true, message: '請輸開示主題', trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
            category: [
              { required: true, message: '請選擇分類', trigger: 'blur' },
            ]
          },
          assignRules: {
            action: [
              { required: true, message: '請選擇籤標動作', trigger: 'blur' },
            ],
          },
          tagExps: [],
        }
  },
  methods:{
    numberToDateString(value){
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
          return moment(parseInt( value)).tz(tz).format('YYYY-MM-DD');
    },
    tableCellClassName({row, column, rowIndex, columnIndex}) {
      const now = new Date().getTime();
      if(column.property === 'start_date'){
        if (row.start_date > now) {
          return 'warning-cell';
        } 
      }
      if(column.property === 'end_date'){
        if(row.end_date < now){
            return 'warning-cell';
        }
      }
      return '';
    },
    batchDelete() {
      this.$confirm('此操作將永久刪除該音檔, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('audio/batchDelete', {audios: this.multipleSelection.map(i=>i.id)} , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    submitBatch() {
      this.loading = true;
      this.$store.dispatch('audio/batch', {audios: this.multipleSelection.map(i=>i.id),...this.batchForm, tagExps: this.tagExps}, {
            root: true
        }).then(()=>{
          this.loading = false;
          this.dialogBatchEditFormVisible =false;
          this.query(this.pageSize,this.offset, this.sort, this.order ,this.queryParams)
        })
    },
    submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
      },
      submitAdd() {
        this.$store.dispatch('audio/add', {...this.form ,tagExps: this.tagExps}, {
            root: true
        }).then( res => {
          if(res.data.id){
            this.upload.data.audio_id = res.data.id;
            this.form.id=res.data.id;
            if(this.form.fileList.length >0){
              this.$refs['upload'].submit();
              this.loading = true;
            } else {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogFormVisible = false;
              this.clearForm();
              this.query(this.pageSize,this.offset, this.sort, this.order ,this.queryParams)
            }
          }
        })
      },
      submitUpdate() {
      this.$store.dispatch('audio/update', {...this.form ,tagExps: this.tagExps} , {
          root: true
      }).then( res => {
        
        if(this.form.fileList.length >0){
          this.upload.data.audio_id = this.form.id;
          this.$refs['upload'].submit();
          this.loading = true;
        } else {
          this.$message({
            message: '更新成功',
            type: 'success'
          });
          this.dialogFormVisible = false;
          this.clearForm();
          this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
        }


      })
    },
      submitUpload() {
        this.$refs.upload.submit();
      },
      submitAssign() {
         this.$refs['assignForm'].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.$store.dispatch('audioAccount/post',{
              audios: this.multipleSelection.map(i=>i.id),
              action: this.assignForm.action,
              accounts: this.assignForm.accounts.map(i=>i.id),
              expiration_date: this.assignForm.expiration_date,
            },{root: true})
            .then(()=>{
              this.loading = false;
              this.dialogAssignVisible =false;
            })
          } else {
            return false;
          }
        });
      
    },
      handleSuccess(response, file, fileList) {
        
        this.$message({
          message: '上傳成功',
          type: 'success'
        });
        this.loading=false;
        this.dialogFormVisible = false;
        this.clearForm();
        this.query(this.pageSize,this.offset,this.sort,this.order,this.queryParams)
      },
      handleError(err, file, fileList) {
       
        this.$message.error(`上傳失敗。 ${err.message}`);  
        this.loading=false;
      },
      handleRemove(file, fileList) {
        
      },
      handleChange(file, fileList) {
        this.form.fileList = fileList
        
      },
    beforeUpload() {
        if( new Date().getTime()/1000 +60> this.$store.state.auth.access_token_exp && this.$store.state.auth.access_token_exp > 0) {
          return this.$store.dispatch('auth/refresh',null,{root: true}).then(res=>{
            this.upload.headers.Authorization=`Bearer ${this.$store.state.auth.access_token}`;
            return true;
          })
        } else {
          if(this.$store.state.auth.access_token) {
           this.upload.headers.Authorization=`Bearer ${this.$store.state.auth.access_token}`;
          }
          return true;
        }
    },
    clearForm() {
      if(this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      
      this.form.id=null;
      this.form.audio_no='';
      this.form.title=''
      this.form.tags=[];
      this.form.start_date= null;
      this.form.end_date= null;
      this.form.description ='';
      this.form.category = []
      this.form.fileList =[]
      this.form.audio_file_info='';
      if(this.$refs['upload']) {
        this.$refs['upload'].clearFiles();
      }
    },
    remoteOptionQuery(query){
        if (query !== '') {
          const store = this.$store;
          query.split(/\s|,/).map(it=>it.trim()).filter(it=>it.length).forEach(async (it)=>{
            console.log(it);
            await store.dispatch('account/list', {sort: 'no', order: 'ascending', qstr:it,is_options: true, max: 100000, offset: 0}, {root: true });
            this.accounts.forEach(element => {
              if(this.assignForm.accounts.findIndex((it)=>it.id==element.id)<0){
                this.assignForm.accounts.push(element);
              }
            });
          })
        } else {
          this.options = [];
        }
      },
    batchAssign() {
      this.$store.dispatch('account/clear', {}, {
          root: true
      });
      
      if(this.$refs['assignForm']) {
        this.$refs['assignForm'].resetFields();
      }
      this.dialogAssignVisible = true;
    },
    batchEditAudio() {
      this.$store.dispatch('category/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      }).then(res=>{
        this.tagExps = this.tags.map(t=>{
          return {id:t.id, name: t.name ,type: t.type, exp: null}
        })
      });
      if(this.$refs['batchForm']) {
        this.$refs['batchForm'].resetFields();
      }
      this.dialogBatchEditFormVisible = true;
    },
    addAudio() {
      this.clearForm();
      this.form.start_date = moment().startOf('day').toDate().getTime();
      this.$store.dispatch('category/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      }).then(res=>{
        this.tagExps = this.tags.map(t=>{
          return {id:t.id, name: t.name ,type: t.type, exp: null}
        })
      });
      this.dialogFormVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order,this.queryParams);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order,this.queryParams);

      },
    query(max,offset,sort ,order, params = {}) {
      this.$store.dispatch('audio/list', {max: max, offset: offset, sort, order, ...params}, {
          root: true
      });
    },
    download(sort ,order, params = {}) {
      API.audio.download({ sort, order, ...params}).then(res=>{
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', 'export.xlsx'); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();

      })
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    indexFormatter2(index){
     return `#${index + 1}`;
    },
    categoryFormatter(row, column, cellValue) {
      if(row.ancestors){
        return row.ancestors.map(a=>a.name).join(' / ');
      } else {
        if(row.category) {
          return row.category.name;
        }
        
      }
      return '';
    },
    fileSizeFormatter(row, column, cellValue){
      if(cellValue) {
        return `${(cellValue/1024.0/1024.0).toFixed(2)} MB`
      } else {
        return '0';
      }
    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
   editAudio(audio_id) {
      this.clearForm();
      this.$store.dispatch('category/list', {max: 1000, offset: 0}, {
          root: true
      });
      const data = this.getAudioById(audio_id);
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      }).then(res=>{
        this.tagExps = this.tags.map(t=>{
          return {id:t.id, name: t.name ,type: t.type, exp: null}
        })
        
        this.form.id=data.id;
        this.form.audio_no=data.audio_no;
        this.form.title=data.title;
        this.form.tags=data.audioToAudioTags.map(tag=>tag.tag_id);
        for (const tag of data.audioToAudioTags) {
          const tagExp = this.tagExps.find(t=>t.id == tag.tag_id);
          if(tagExp){
              tagExp.exp = tag.expiration_date;
          }
          
        }
        this.form.start_date= data.start_date;
        this.form.end_date= data.end_date;
        this.form.description = data.description
        if(data.audio_filename){
          let audioFileSize = this.readableBytes(data.audio_file_size);
          this.form.audio_file_info=data.audio_filename + '  (' + audioFileSize + ')';
        } else {
          this.form.audio_file_info='';
        }
        if (data.category) {
          API.category.ancestors(data.category.id).then(res=>{
            this.form.category = res.data.map(d=>d.id);
            this.dialogFormVisible = true;
          })
        } else {
          this.dialogFormVisible = true;
        }

        
      });
   
       
    },
    readableBytes(bytes) {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
    },
    deleteAudio(audio_id) {
      this.$confirm('此操作將永久刪除該音檔, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('audio/remove', audio_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('audio/SET_SORT','id',{root: true})
        this.$store.commit('audio/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('audio/SET_SORT',args.prop,{root: true})
        this.$store.commit('audio/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order,this.queryParams)
    }
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          accounts: 'account/accounts',
          audios: 'audio/audios',
          total:'audio/total',
          max:'audio/max',
          offset:'audio/offset',
          categories: 'category/categories',
          subCategories: 'subCategory/subCategories',
          tags: 'tag/tags',
          getAudioById: 'audio/getAudioById',
          sort: 'audio/sort',
          order: 'audio/order',
      }),
  },
}
</script>

<style scoped>
  .tag_group .el-tag+span {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer-panel, .el-cascader {
    width: 350px;
  }
  .el-date-editor.el-input{
    width: 150px;
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
</style>
